/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfessionalImportGetFacilitiesRequest } from '../models/ProfessionalImportGetFacilitiesRequest';
import type { ProfessionalImportGetFacilitiesResponse } from '../models/ProfessionalImportGetFacilitiesResponse';
import type { ProfessionalImportGetInstancesResponse } from '../models/ProfessionalImportGetInstancesResponse';
import type { ProfessionalImportPendingResponse } from '../models/ProfessionalImportPendingResponse';
import type { Response } from '../models/Response';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SupApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Impersonate the doctor with the given CPF
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public impersonate(
    requestBody: {
      cpf?: string;
      userId?: string;
    },
  ): CancelablePromise<(Response & {
    data: any;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sup/impersonate',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Validate and insert the given CSV. All data must be valid.
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public professionalImportInsert(
    requestBody: {
      csv: string;
    },
  ): CancelablePromise<(Response & {
    data: any;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sup/professional-import/insert',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Queue the already inserted professional_imports for importation
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public professionalImport(
    requestBody: {
      medicalFacility: string;
      ids: Array<string>;
    },
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sup/professional-import/import',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return the summary for the given instance
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public getProfessionalImportSummary(
    requestBody: {
      instance: string;
    },
  ): CancelablePromise<(Response & {
    data: any;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sup/professional-import/summary',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return the results for the given instance and facility
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public getProfessionalImportResults(
    requestBody: {
      facility: string;
      instance: string;
    },
  ): CancelablePromise<(Response & {
    data: any;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sup/professional-import/results',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return the pending professional_imports
   * @param requestBody
   * @returns ProfessionalImportPendingResponse Returns success/APIError
   * @throws ApiError
   */
  public getPendingProfessionalImports(
    requestBody: {
      medicalFacility: string;
      instance: string;
    },
  ): CancelablePromise<ProfessionalImportPendingResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sup/professional-import/get-pending',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return medical facilities for the given instance
   * @param requestBody
   * @returns ProfessionalImportGetFacilitiesResponse Returns success/APIError
   * @throws ApiError
   */
  public getProfessionalImportFacilities(
    requestBody: ProfessionalImportGetFacilitiesRequest,
  ): CancelablePromise<ProfessionalImportGetFacilitiesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sup/professional-import/get-facilities',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Return medical instances for the given instance
   * @returns ProfessionalImportGetInstancesResponse Returns success/APIError
   * @throws ApiError
   */
  public getShiftManagerInstances(): CancelablePromise<ProfessionalImportGetInstancesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/sup/professional-import/get-instances',
    });
  }

}
