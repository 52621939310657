/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ReviewStatus } from './ReviewStatus';

export type AssignContractReviewer = {
  contractId: string;
  contractReviewerId: string;
  contractReviewerType: AssignContractReviewer.contractReviewerType;
  reviewStatus: ReviewStatus;
};

export namespace AssignContractReviewer {

  export enum contractReviewerType {
    FIRST_REVIEWER = 'FIRST_REVIEWER',
    SECOND_REVIEWER = 'SECOND_REVIEWER',
  }


}

