import { fileToArrayBuffer, generateRandomString } from '@commons/js-utils';
import {
  ErrorCode,
  WorkflowStepId,
  Response,
  ApiClient as DoctorOnboardingAPIV2,
} from '@medsimples/doctor-onboarding-openapi-v2';
import {
  DoctorOnboardingAPI,
  Workflow,
} from '@medsimples/doctor-onboarding-openapi/dist/client';
import { ApiClient as IdwallAPI } from '@medsimples/idwall-openapi';
import { OnboardingRoutes } from './utils/routing';
import * as E from 'fp-ts/Either';
import { Sentry, reportSentryError } from './sentry';

export interface OnboardingAPIConfig {
  baseURL?: string;
  headers?: Record<string, string>;
  cookies?: Record<string, string>;
}

export const ProxyAPI = (config: OnboardingAPIConfig = {}) => {
  const proxiedServices = [
    'doctor',
    'user',
    'company',
    'userTerm',
  ];

  const proxy = new Proxy(doctorOnboardingAPI(config), {
    get(target, p) {
      if (proxiedServices.includes(p as string)) {
        const clazz = target[p];
        return new Proxy(clazz, {
          get(tdoc, pdoc) {
            if (typeof tdoc[pdoc] === 'function') {
              return (...args: any[]) => {
                return new Promise((resolve, reject) => {
                  tdoc[pdoc](...args)
                    .then((r) => {
                      if (r?.success === false && (!r?.error?.code || r?.error?.code === ErrorCode.ServerInternalError)) reportSentryError(new Error('Onboarding API Error'), null, null, { 'api.from_reponse': true, 'is_api': true, 'api.service': p as string, 'api.operation': pdoc as string });
                      resolve(r);
                    })
                    .catch((err) => {
                      reportSentryError(err, null, null, { 'is_api': true, 'api.service': p as string, 'api.operation': pdoc as string });
                      reject(err);
                    });
                });
              };
            }
          }
        });
      }
      return target[p];
    },
  });

  return proxy;
};

export const doctorOnboardingAPI = (
  { baseURL, headers, cookies }: OnboardingAPIConfig = {},
) =>
  new DoctorOnboardingAPI({
    BASE: `${baseURL ?? ''}/api/v1`,
    HEADERS: _getHeaders(headers, cookies),
  });

export const doctorOnboardingAPIV2 = (
  { baseURL, headers, cookies }: OnboardingAPIConfig = {},
) =>
  new DoctorOnboardingAPIV2({
    BASE: `${baseURL ?? ''}/api/v1`,
    HEADERS: _getHeaders(headers, cookies),
  });

export const idwallAPI = new IdwallAPI({
  BASE: '/api/idwall',
});

function _getHeaders(
  headers?: Record<string, string>,
  cookies?: Record<string, string>,
): Record<string, string> {
  const Cookie = cookies
    ? Object.entries(cookies).map(([k, v]) => `${k}=${v}`).join(';')
    : '';
  return {
    ...(headers ?? {}),
    Cookie,
  };
}

export const Api = ProxyAPI;

const errorMessages: Partial<Record<ErrorCode, string>> = {
  e000001: 'Permissão negada.',
  e000002: 'Requisição inválida.',
  e000003: 'Não autorizado.',
  e000004: 'Erro no servidor da aplicação.',
  e001001: 'O fluxo de cadastro do usuário já começou.',
  e001002: 'Não foi possível encontrar o passo para a etapa do usuário.',
  e001003: 'Passo já existente para o cadastro do usuário.',
  e001004: 'Código de verificação inválido. Tente novamente.',
  e001005: 'Falha ao realizar login, telefone não informado.',
  e001006: 'Usuário não encontrado.',
  e001007: 'Usuário inválido.',
  e001008: 'Cadastro do usuário não encontrado.',
  e001009: 'Falha ao realizar login, telefone não informado.',
  e001010: 'Erro ao buscar lista de bancos.',
  e001011: 'Você atingiu o número máximo de requisições. Tente novamente dentro de 30 minutos.',
  e001012: 'Erro ao realizar login. Tente novamente.',
  e001013: 'Erro ao buscar banco.',
  e001015: 'Domínio da aplicação não cadastrado, por favor confira e tente novamente.',
  e002001: 'Cadastro de profissional não encontrado para o médico.',
  e002002: 'Usuário não encotrado para o médico.',
  e002003: 'Não foi possível encontrar um médico com os dados informados',
  e002004: 'Erro ao validar o CPF informado. Verifique se é um CPF válido e tente novamente',
  e002005: 'Não foi possível relacionar o CPF com o CRM. Verifique se o seu nome está atualizado no cadastro do CRM, atualize seu cadastro e tente novamente.',
  e002006: 'Ops! Parece que o CRM já está em uso por outro usuário. Se você é o dono dessa informação, entre em contato através do botão abaixo.',
  e002007: 'Ops! Parece que o CPF já está em uso por outro usuário. Se você é o dono dessa informação, entre em contato através do botão abaixo.',
  e003001: 'Não foi encontrado um profissional cadastrado.',
  e003002: 'Erro ao sincronizar o profissional',
  e003003: 'Erro ao listar documentos do profissional.',
  e003006: 'Documentos obrigatórios do profissional não preenchidos.',
  e004001: 'O usuário atual não possui cadastro de pessoa fisica, assim não sendo possível encontrar uma empresa.',
  e004002: 'Não foi possível encontrar a empresa solicitada para o usuário.',
  e004003: 'Não foi possível encontrar a empresa solicitada.',
  e004004: 'Não foi possível relacionar o CPF do usuário com a empresa.',
  e004005: 'O registro da empresa não está válido.',
  e004006: 'Erro ao buscar validação da conta de banco da empresa.',
  e004007: 'Erro ao validar a conta de banco da empresa.',
  e004008: 'Conta de banco da empresa não foi encontrada.',
  e004009: 'Você atingiu o número máximo de requisições. Tente novamente dentro de 30 minutos.',
  e004010: 'Operação inválida.',
  e004011: 'Tipo da empresa não é aceito.',
  e004012: 'Erro ao listar documentos da empresa.',
  e004013: 'Erro ao processar conta bancária.',
  e004016: 'A exclusão da empresa cadastrada anteriormente não é permitida.',
  e004017: 'Documentos obrigatórios da empresa não preenchidos.',
  e005001: 'Número de telefone já cadastrado, por favor, use outro, ou entre em contato em caso de problemas.',
  e005002: 'Email já cadastrado, por favor, use outro, ou entre em contato em caso de problemas.',
  e005003: '',
  e005004: 'RG já cadastrado, por favor, use outro, ou entre em contato em caso de problemas.',
  e005005: 'CPF de um usuário já existente não pode ser alterado.',
  e006001: 'Profissional não encontrado. Abortando o processamento.',
  e006002: 'Erro ao buscar o profissional.',
  e006003: 'Erro ao criar e expandir Bussiness Partner',
  e006004: '',
  e006005: '',
  e007001: 'Erro ao buscar score da empresa na Neoway',
  e008001: 'Não foi encontrado uma pessoa com o CPF informado.',
  e008002: 'Não é possível impersonar uma pessoa que não está com cadastro em progresso ou ainda não passou do reconhecimento facial.',
  e010001: 'Faltam informações em um ou mais assinantes selecionados.'
} as const;

const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro!';

export const getErrorMessage = (code: string): string => {
  return errorMessages[code] ?? DEFAULT_ERROR_MESSAGE;
};

export const handleAPIReq = async <T extends Response>(func: () => Promise<T>): Promise<E.Either<string, T>> => {
  try {
    const result = await func();
    return handleAPIRes(result);
  } catch (err) {
    console.error('Unexpected error requesting api');
    return E.left('Ocorreu um erro inesperado!');
  }
};

export const handleAPIRes = <T extends Response>(result: T): E.Either<string, T> => {
  if (result?.error?.code) {
    return E.left(getErrorMessage(result.error?.code));
  }
  if (!result || !result?.success) return E.left('Ocorreu um erro inesperado!');

  return E.right(result);
};

export async function uploadFiletoR2(r2WorkerUrl: string, file: File): Promise<string> {
  try {
    const fileArrayBuffer = await fileToArrayBuffer(file);
    const randomKey = generateRandomString(512);
    const fileName = file.name;
    const reqUrl = [r2WorkerUrl, randomKey, fileName]
      .join('/');
    const resp = await fetch(reqUrl, {
      method: 'PUT',
      body: fileArrayBuffer,
      headers: {
        'Content-Type': file.type,
      },
    });
    const respJson: { data: { key: string } } = await resp.json();
    return respJson.data.key;
  } catch(err) {
    Sentry.captureException(err, {
      tags: {
        'err.message': err?.message,
        'err.stack': err?.stack,
        'file.name': file?.name,
        'file.size': file?.size,
        'file.type': file?.type,
      },
    });
    throw err;
  }
}

export const getUserNextRoute = async (
  { requestedStepId, config }: {
    requestedStepId?: WorkflowStepId,
    config?: OnboardingAPIConfig,
  } = {},
) => {
  const result = (await Api(config).user.getUserWorkflowNextStep({
    workflowId: Workflow.ONBOARDING,
    requestedStepId,
  })).data;

  if (result.toStatus) {
    return OnboardingRoutes.STATUS.path;
  }

  if (result.workflowStepId) {
    return OnboardingRoutes[result.workflowStepId].path;
  }

  return OnboardingRoutes.STATUS.path;
};
