import { WorkflowStepId } from '@medsimples/doctor-onboarding-openapi-v2';

export interface OnboardingPage {
  path: string;
}

type ExtraRoutes = 'STATUS' | 'ONBOARDING_COMPANY_CONFIRM_INFO_ADDRESS' | 'ONBOARDING_COMPANY_CONFIRM_INFO_STRUCTURE';

export const OnboardingRoutes: Record<WorkflowStepId | ExtraRoutes, OnboardingPage> = {
  ONBOARDING_DOCTOR_BASIC_INFO: {
    path: '/basic_info',
  },
  ONBOARDING_DOCTOR_CONFIRM_BASIC_INFO: {
    path: '/confirm_basic_info',
  },
  ONBOARDING_PERSONAL_ADDRESS: {
    path: '/personal_address',
  },
  ONBOARDING_FACIAL_RECOGNITION: {
    path: '/facial_recognition',
  },
  ONBOARDING_PROFESSIONAL_DOCUMENTS: {
    path: '/professional_documents',
  },
  ONBOARDING_COMPANY_SEARCH_INFO: {
    path: '/company_info',
  },
  ONBOARDING_COMPANY_CONFIRM_INFO: {
    path: '/confirm_company_info',
  },
  ONBOARDING_COMPANY_CONFIRM_INFO_ADDRESS: {
    path: '/company_address',
  },
  ONBOARDING_COMPANY_CONFIRM_INFO_STRUCTURE: {
    path: '/corporate_structure',
  },
  ONBOARDING_COMPANY_DOCUMENTS: {
    path: '/company_documents',
  },
  ONBOARDING_COMPANY_BANK_INFO: {
    path: '/bank_account',
  },
  ONBOARDING_COMPLIANCE: {
    path: '/compliance',
  },
  ONBOARDING_USE_TERMS: {
    path: '/terms_and_conditions',
  },
  STATUS: {
    path: '/registration_status',
  }
} as const;
